<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PRODUCT CATEGORY</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          class="w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first('name')
        }}</span>
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions } from 'vuex';

import { ProductCategoryAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      dataName: '',
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, name } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataName = name;

        this.initValues();
      }
    },
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.dataName;
    },
  },
  methods: {
    ...mapActions('product-category', [
      ProductCategoryAction.add,
      ProductCategoryAction.update,
    ]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataName = '';
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        name: this.dataName,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateProductCategory(obj);

          this.notifySuccess(
            'Product category updated',
            'The product category has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a category.');
        }
      } else {
        delete obj.id;

        try {
          await this.addProductCategory(obj);

          this.notifySuccess(
            'Product category added',
            'The product category has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a category.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
